import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import App from './App.vue'
import HomeComponent from './components/HomeComponent'
import DetailComponent from './components/DetailComponent'
import LPBuilder from './components/LPBuilder'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "jquery"

const routes = [
    { path: '/', component: HomeComponent },
    { path: '/detail/:id', component: DetailComponent },
    { path: '/enroll/:id', component: LPBuilder }
]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})

const app = createApp(App)
app.use(router)
app.mount('#app')
