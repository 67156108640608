<template>
    <div id="page-container" class="body-wrapper">
      <div v-if="isLoading" class="mb-2 my-5">
          <div class="text-center">
              <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
              </div>
          </div>
      </div>
      <div class="home-content home page-template-default page page-id-2 et-tb-has-template et-tb-has-header et-tb-header-disabled et-tb-has-footer et_button_no_icon et_pb_button_helper_class et_cover_background et_pb_gutter windows et_pb_gutters3 et_pb_pagebuilder_layout et_no_sidebar et_divi_theme et-db chrome" v-if="showContent" v-html="bodyContent" @click="handleCellClick"></div>
    </div>
</template>
  
<script>

  import axios from 'axios';
  export default {
    name: 'HomeComponent',
    props: {
    },
    components: {
    },
    methods: {
        fetchContent() {
            const headers = { 
            "Authorization": "Bearer LHmRduM4cAZWpLqI1KrOtIoJ7YDdlVor"
            };
            axios.post("https://api.iti.com/api/v1/lpb/home", { email: this.customerEmail }, { headers })
            .then(response => {
                this.bodyContent = ''
                if(response.data.html != '') {
                    this.bodyContent = response.data.html
                    this.showContent = true
                }
                else {
                    this.showContent = false
                }
                this.isLoading = false
            })
            .catch(() => {
                this.bodyContent = ''
                this.showContent = false
                this.isLoading = false
            });
        },
        handleCellClick(e) {
          e.preventDefault();
          if(e.target.matches('.cell-link')) {
            const id = e.target.getAttribute('data-id');
            this.$router.push({ path: '/detail/' + id });
          }
          else if(e.target.matches('.custom-cell-link')) {
            this.$router.push({ path: '/enroll/custom' });
          }
          else if(e.target.matches('.existing-customer')) {
            this.$router.push({ path: '/enroll/existing' });
          }
          else if(e.target.matches('.job-title-category')) {
            const selected = !e.target.parentNode.classList.contains('selected');
            const links = document.querySelectorAll(".tag");
            for (var j = 0; j < links.length; j++) {
              links[j].classList.remove('selected');
            }
            if(selected) {
              e.target.parentNode.classList.add('selected');
            }
            const typeId = e.target.getAttribute('data-id');
            const courses = document.querySelectorAll(".course-wrapper");
            for (var i = 0; i < courses.length; i++) {
              if(selected) {
                var categoryIds = courses[i].getAttribute('data-type');
                categoryIds = categoryIds !== '' ? categoryIds.split(',') : [];
                if(categoryIds.includes(typeId)) {
                  courses[i].classList.remove('hide');
                }  
                else {
                  courses[i].classList.add('hide');
                }
              }
              else {
                courses[i].classList.remove('hide');
              }
            }
          }
          return false;
        }
    },
    beforeMount() {
        if(sessionStorage.getItem('customer_email') !== null) {
          this.customerEmail = sessionStorage.getItem('customer_email')
        }
        this.fetchContent()
    },
    data() {
      return {
        showContent: false,
        customerEmail: '',
        bodyContent: '',
        isLoading: true
      };
    }
  }
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
</style>
  