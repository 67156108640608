<template>
    <div id="page-container" class="body-wrapper">
        <div v-if="isLoading" class="my-5">
            <div class="text-center">
                <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
        <div class="detail-content html not-front not-logged-in no-sidebars page-node page-node- page-node-9649 node-type-activity-description course-content-background-not-inherited i18n-origin page-content-background-enabled mtx-light-theme stored-data-reset-processed blazy-processed" v-if="showContent" v-html="bodyContent" @click="handleCellClick"></div>
    </div>
</template>
  
<script>
  import axios from 'axios';
  export default {
    name: 'DetailComponent',
    props: {
    },
    components: {},
    methods: {
        fetchContent() {
            const headers = { 
            "Authorization": "Bearer LHmRduM4cAZWpLqI1KrOtIoJ7YDdlVor"
            };
            axios.post("https://api.iti.com/api/v1/lpb/detail", { titleId: this.$route.params.id }, { headers })
            .then(response => {
                this.bodyContent = ''
                if(response.data.html != '') {
                    this.bodyContent = response.data.html
                    this.showContent = true
                }
                else {
                    this.showContent = false
                }
                this.isLoading = false
            })
            .catch(() => {
                this.bodyContent = ''
                this.showContent = false
                this.isLoading = false
            });
        },
        handleCellClick(e) {
            e.preventDefault();
            if(e.target.matches('.catalog-enroll-now')) {
                const id = e.target.getAttribute('data-id');
                this.$router.push('/enroll/' + id);
            }
            else if(e.target.matches('.back-link')) {
                this.$router.back()
            }
            else if(e.target.matches('.external-link')) {
                window.location.href = e.target.getAttribute('href');
            }
        }
    },
    beforeMount() {
        this.fetchContent()
    },
    data() {
      return {
        showContent: false,
        bodyContent: '',
        isLoading: true
      };
    }
  }
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .detail-content { text-align: left; }
</style>
  