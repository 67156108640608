<template>
  <div class="body-wrapper my-2">
    <div v-if="isLoading" class="mb-2">
      <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <iframe :src="iframeUrl.concat('&iframe=1')" 
    style="width:100%;height:3500px;" @load="iframeLoaded" id="lpbIframe"></iframe>
  </div>
</template>
  
<script>
  window.jQuery = require('jquery');
  window.addEventListener('message', function (e) {
    const data = e.data;
    if (typeof data !== 'string' && !(data instanceof String))
      return;
    if(data.indexOf('scroll_top') > -1) {
      var top = parseInt(data.replace('scroll_top_',''));
      top += window.jQuery('.body-wrapper').offset().top - 55;
      window.jQuery('html,body').scrollTop(top);
    }
    else if(data.indexOf('height') > -1) {
      var height = parseInt(data.replace('height_',''));
      window.jQuery('#lpbIframe').css('height',(height + 10) + 'px');
    }
  });
  export default {
    name: 'IFrameComponent',
    props: {
      iframeUrl: String
    },
    components: {
    },
    methods: {
      iframeLoaded() {
        this.isLoading = false
      }
    },
    data() {
      return {
        isLoading: true
      };
    }
  }
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>
  