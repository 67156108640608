<template>
    <div class="body-wrapper position-relative">
      <div class="alert alert-danger" v-if="hasError">{{ errorMessage }}</div>
      <div class="position-absolute hero-button">
        <button class="btn btn-primary me-2" v-if="addUrl !== '' && !backToItineraries && hideViewBtn" @click="$event => addItinerary()">Submit a new Learning Path</button>
        <button class="btn btn-primary me-2" v-if="!hideViewBtn && itinerariesUrl !== '' && !backToItineraries" @click="$event => fetchItineraries()">View Saved Learning Path(s)</button>
        <button class="btn btn-primary" v-if="backToItineraries" @click="$event => backToItinerariesFunc()">Back To Learning Paths</button>
      </div>
      <div class="m-auto mt-3" :class="{hide: showWizard || showIframe}">
        <div class="page-center text-left pwr--relative">
          <div class="pwr-sec-steps__intro-sec pwr-sec__intro-sec mb-0">
            <h2 class="text-left pwr-sec__title pwr-heading-style--default pwr-sec__title--narrow pwr-sec-steps__title fw-bold">HOW IT WORKS...</h2>
          </div>
          <div class="row-fluid">
            <div class="span12 pwr--relative">
              <div class="pwr-steps   pwr-steps--3 pwr--neg-margin-lr-10 pwr--clearfix">
                <div class="pwr-step pwr-step--3-col aos-init aos-animate">
                  <div class="pwr-mh--wrapper" data-pwr-mh="mhID0" style="height: 96px;">
                    <img data-src="https://78935.fs1.hubspotusercontent-na1.net/hubfs/78935/ITI_Web_4.0_Maple/ITI%20Logos%20and%20Icons%202017/ITI_Icon_InfoKits.svg" class="pwr-step__img lazyloaded" alt="ITI_Icon_InfoKits" width="60" style="aspect-ratio: auto 1.0; " src="https://78935.fs1.hubspotusercontent-na1.net/hubfs/78935/ITI_Web_4.0_Maple/ITI%20Logos%20and%20Icons%202017/ITI_Icon_InfoKits.svg">
                  </div>
                  <div class="pwr-mh--wrapper" data-pwr-mh="mhID1" style="height: 42px;"><span class="pwr-step__title pwr-step__title--has-desc">Step 1</span></div>
                  <span class="pwr-rich-text pwr-step__desc" data-pwr-mh="mhID2"><p>A bit about you and your team...</p>
                    <ul>
                      <li>Contact Information</li>
                      <li>Industry Information</li>
                    </ul>
                  </span>
                  <div class="pwr-mh--wrapper" data-pwr-mh="mhID3" style="height: 1px;"></div>
                </div>
                <div class="pwr-step pwr-step--3-col aos-init aos-animate" data-aos="fade-in" data-aos-delay="200">
                  <div class="pwr-mh--wrapper" data-pwr-mh="mhID0" style="height: 96px;">
                    <img data-src="https://78935.fs1.hubspotusercontent-na1.net/hub/78935/hubfs/ITI_Web_4.0_Maple/ITI%20Logos%20and%20Icons%202017/ITI_Icon_Library.png?width=82&amp;name=ITI_Icon_Library.png" class="pwr-step__img lazyloaded" alt="ITI_Icon_Library" width="82" style="aspect-ratio: 1.3666666666666667; " src="https://78935.fs1.hubspotusercontent-na1.net/hub/78935/hubfs/ITI_Web_4.0_Maple/ITI%20Logos%20and%20Icons%202017/ITI_Icon_Library.png?width=82&amp;name=ITI_Icon_Library.png">
                  </div>
                  <div class="pwr-mh--wrapper" data-pwr-mh="mhID1" style="height: 42px;"><span class="pwr-step__title pwr-step__title--has-desc">Step 2</span></div>
                  <span class="pwr-rich-text pwr-step__desc" data-pwr-mh="mhID2"><p>Filter, review, and analyze training content from:</p>
                    <ul>
                      <li>ITI</li>
                      <li>ITI Content Partners</li>
                    </ul>
                  </span>
                  <div class="pwr-mh--wrapper" data-pwr-mh="mhID3" style="height: 1px;"></div>
                </div>
                <div class="pwr-step pwr-step--3-col aos-init aos-animate" data-aos="fade-in" data-aos-delay="350">
                  <div class="pwr-mh--wrapper" data-pwr-mh="mhID0" style="height: 96px;">
                    <img data-src="https://78935.fs1.hubspotusercontent-na1.net/hubfs/78935/ITI_Web_4.0_Maple/ITI%20Logos%20and%20Icons%202017/ITI_Icon_Download.svg" class="pwr-step__img lazyloaded" alt="ITI_Icon_Download" width="60" style="aspect-ratio: auto 1.0; " src="https://78935.fs1.hubspotusercontent-na1.net/hubfs/78935/ITI_Web_4.0_Maple/ITI%20Logos%20and%20Icons%202017/ITI_Icon_Download.svg">
                  </div>
                  <div class="pwr-mh--wrapper" data-pwr-mh="mhID1" style="height: 42px;"><span class="pwr-step__title pwr-step__title--has-desc">Step 3</span></div>
                  <span class="pwr-rich-text pwr-step__desc" data-pwr-mh="mhID2"><p>Check out what you have built!</p>
                    <ul>
                    <li>1-on-1 consult with an ITI Training Solutions Advisor</li>
                    <li>Generate a report of your learning path and share internally with your stakeholders</li>
                    </ul>
                  </span>
                  <div class="pwr-mh--wrapper" data-pwr-mh="mhID3" style="height: 1px;"></div>
                </div>
              </div>
            </div>    
          </div>
        </div>
        <h4 class="fw-bold mt-2">GET STARTED NOW BY ENTERING YOUR EMAIL ADDRESS</h4>
        <input type="text" class="form-control m-auto w-50 d-block my-3 text-center mb-0" autofocus placeholder="Enter your email address..." v-model="customerEmail" />
        <div class="text-danger text-center my-2" v-if="!validEmail">Please enter a valid email</div>
        <button class="btn btn-lg p-0" @click="$event => checkEmail()"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB00lEQVR4nO3bwUoCQRjAcU/VC1TqjkohVM6X9TJROFPROYTeK3qCiIgIoqCgk/kUQXU2lhI8KJTtfuOO/x/M2Zk/4zitVioBAAAAAAAAAAAAwJyotA7r5a1OI/Q8orXa9itG3J0RP/gZ17WNg2roeUXHiLsYiTwcfbNzlISeW1SMdR9jQhM7axMiE1s5NDtbMTSxFUMTWzE0sRVDE1sxNLEVQxNbMTSxFUMTWzE0sRVD5xu70TheSqw7S6x7NNZ9ZjjpQSGHda+ZP2Kt2v1aYv1L8MVJxLG/dzKRTd6x0+Mi+M6ROYidiH8IvhCZg9hG/HvwRUhhxvS3kRmY/GAudnbwiUsRh7sitOjETq/E7GhRiP3Xszr829AXb1h3ydEheUf2vfqmq0wTmuudKNyljbj74G9FiXgnj4TuBl+ERB451Wx2F4345+CLkRkd1vcye4KXXlWMuKfgi5KIIw+1WnsLpuVP098XJ9a/BV+kRBg5FmaWv12JiSGyDnayEo4LJZzJSvjgU8LtQglXOCXck2crdJ8/Rv7pN5H5L9oMEFnJxG+LeHaRLSP+nMgKyrsny4m422HsxPobPvhyVN/urNfbfi3P1wAAAAAAAAAAAACA0hhfBfny4RnyXXAAAAAASUVORK5CYII="></button>
      </div>
      <div class="wizard-form" :class="{hide: !showWizard || showIframe}">
        <vue-form-wizard
          :form="questions"
          v-model="formData"
          @submit="handleSubmit(formData)"
          @next="handleNext()"
          @previous="handlePrevious()"
        />
      </div>
      <div class="courses-list" v-if="showIframe">
        <CoursesComponent v-bind:iframeUrl="iframeUrl"  />
      </div>
    </div>
</template>
  
<script>
  window.jQuery = require('jquery');
  import CoursesComponent from './IFrameComponent.vue'
  import VueFormWizard from '@anivive/vue3-form-wizard'
  import '../assets/css/wizard.css'; 
  import questions from '../questions.json';
  import axios from 'axios';
  export default {
    name: 'LPBuilder',
    props: {
    },
    components: {
      VueFormWizard,
      CoursesComponent
    },
    methods: {
      handlePrevious() {
        this.focusWizardInput();
      },
      handleNext() {
        this.focusWizardInput();
      },
      handleSubmit(data) {
        if(this.customerEmail === '') {
          return;
        }
        data['email'] = this.customerEmail;
        data['title_id'] = this.$route.params.id;
        const headers = { 
          "Authorization": "Bearer LHmRduM4cAZWpLqI1KrOtIoJ7YDdlVor"
        };
        axios.post("https://api.iti.com/api/v1/lpb", data, { headers })
          .then(response => {
            if(response.data.url != '') {
              this.iframeUrl = response.data.url
              this.showIframe = true
              sessionStorage.setItem('customer_email',this.customerEmail)
            }
            else {
              this.errorMessage = 'An error occurred while processing your request'
              this.hasError = true
              setTimeout(() => { this.hasError = false },3000)
            }
          })
          .catch(error => {
            this.errorMessage = error
            this.hasError = true
            setTimeout(() => { this.hasError = false },3000)
          });
      },
      showWizardClick() {
        this.showWizard = true
      },
      validateEmail(email) {
        return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      },
      checkEmail() {
        this.validEmail = true
        if(this.customerEmail === '' || !this.validateEmail(this.customerEmail)) {
          this.validEmail = false
          return;
        }
        const headers = { 
          "Authorization": "Bearer LHmRduM4cAZWpLqI1KrOtIoJ7YDdlVor"
        };
        axios.post("https://api.iti.com/api/v1/lpb/email", { email: this.customerEmail, title_id: this.$route.params.id }, { headers })
        .then(response => {
            if(response.data.url != '') {
              sessionStorage.setItem('customer_email',this.customerEmail)
              if(this.$route.params.id !== 'existing') {
                this.iframeUrl = response.data.url
              }
              else {
                this.iframeUrl = response.data.submission_url
                this.hideViewBtn = true
              }
              this.itinerariesUrl = response.data.submission_url
              this.addUrl = response.data.add_url
              this.showIframe = true
            }
            else {
              this.showWizard = true;
              this.focusWizardInput();
            }
          })
          .catch(() => {
            this.showWizard = true;
            this.focusWizardInput();
          });
      },
      focusWizardInput() {
        setTimeout(function() { 
          window.jQuery('html,body').animate({scrollTop: window.jQuery('.input-component input').offset().top - 200})
          window.jQuery('.input-component input')[0].focus(); 
        },500);
      },
      fetchItineraries() {
        if(this.customerEmail === '') {
          return;
        }
        const headers = { 
          "Authorization": "Bearer LHmRduM4cAZWpLqI1KrOtIoJ7YDdlVor"
        };
        axios.post("https://api.iti.com/api/v1/lpb/itineraries", { email: this.customerEmail }, { headers })
          .then(response => {
            if(response.data.url != '') {
              this.itinerariesUrl = response.data.url
              this.iframeUrl = response.data.url
              this.addUrl = response.data.add_url
              this.showIframe = true
              this.hideViewBtn = true;
            }
            else {
              this.showWizard = true
            }
          })
          .catch(() => {
            this.showWizard = true
          });
      },
      addItinerary() {
        this.$router.push('/')
      },
      backToItinerariesFunc() {
        this.iframeUrl = this.itinerariesUrl
        this.showIframe = true
        this.backToItineraries = false
      }
    },
    beforeMount() {
        if(sessionStorage.getItem('customer_email') !== null) {
          this.customerEmail = sessionStorage.getItem('customer_email')
          this.checkEmail()
        }
    },
    data() {
      return {
        formData: [],
        questions: questions,
        showWizard: false,
        showIframe: false,
        iframeUrl: '',
        hasError: false,
        errorMessage: '',
        customerEmail: '',
        addUrl: '',
        backToItineraries: false,
        itinerariesUrl: '',
        hideViewBtn: false,
        validEmail: true
      };
    }
  }
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>
  